import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

const AIArticles = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLLM, setFilterLLM] = useState(false);
  const limit = 10;

  useEffect(() => {
    const fetchArticles = async () => {
      const response = await axios.get(`https://rss-app-live-5399b7c56aa2.herokuapp.com/api/articles?page=${page}&limit=${limit}&filterLLM=${filterLLM}`);
      setArticles(response.data.articles);
    };

    fetchArticles();
  }, [page, filterLLM]);

  const handlePreviousPage = () => {
    if (page > 1) setPage(page - 1);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const toggleFilter = () => {
    setFilterLLM(!filterLLM);
  };

  return (
    <div className="container">
      <header className="header">
        <img src="/bear-reading-news.png" alt="Bear reading news" className="header-image" />
        <h1>Latest AI Articles</h1>
        <button className="filter-button" onClick={toggleFilter}>
          {filterLLM ? "Show All AI Articles" : "Show Only LLM Articles"}
        </button>
      </header>
      <ul className="list-unstyled articles-list">
        {articles.map(article => (
          <li key={article.id} className="card">
            <div className="card-body">
              <h5 className="card-title">
                <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
              </h5>
              <p className="card-date">{new Date(article.pubDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
              <p className="card-text" dangerouslySetInnerHTML={{ __html: article.summary.replace(/\n/g, '<br/>') }} />
            </div>
          </li>
        ))}
      </ul>
      <div className="pagination">
        <button onClick={handlePreviousPage} disabled={page === 1}>Previous</button>
        <button onClick={handleNextPage}>Next</button>
      </div>
    </div>
  );
};

export default AIArticles;
